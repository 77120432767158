import React from 'react';
import ContainImage from '@components/views/ContainImage';
import clsx from 'clsx';
import { convertPixelsToRems } from 'src/utils/convertPixelsToRems';

export interface DecoratorProps {
  position: {
    vertical: 'top' | 'bottom' | 'middle';
    horizontal: 'left' | 'right' | 'middle';
  };
  image: string;
  maxWidth: string;
  behind?: boolean;
}

const Decorator: React.FunctionComponent<DecoratorProps> = ({
  position,
  image,
  maxWidth,
  behind,
}) => {
  if (!image) return null;

  const { vertical, horizontal } = position;
  return (
    <div
      className={clsx('absolute w-full pointer-events-none', {
        'top-0': vertical === 'top',
        'bottom-0': vertical === 'bottom',
        'left-0': horizontal === 'left',
        'right-0': horizontal === 'right',
        'left-1/2 transform -translate-x-1/2': horizontal === 'middle',
        'top-1/2 transform -translate-y-1/2': vertical === 'middle',
        '-z-1': behind,
      })}
      style={{ maxWidth: `${convertPixelsToRems(maxWidth)}rem` }}
    >
      <ContainImage src={image} />
    </div>
  );
};
export default Decorator;
